<template>
    <input
            class="form-control"
            :type="type"
            v-model="modelForm[modelName]"
            @input="inputFunction"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :id="id"
    />
</template>

<script>
export default {
    name: "InputTag",
    props: {
        modelForm: {
            type: Object,
            required: true
        },
        modelName: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "text"
        },
        inputFunction: {
            type: Function,
            default: function () {
            }
        },
        placeholder: {
            type: String,
            default: ""
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: ""
        }
    }
}
</script>

<style scoped>

</style>
