<template>
    <label
        class="form-label"
        :style="labelStyle"
        :for="labelFor"

    >
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: "LabelTag",
    props: {
        labelStyle: {
            type: String,
            default: ''
        },
        labelFor: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>

</style>
